// @flow 
import * as React from 'react';
import {Link, useLocation} from "react-router-dom";
import 'src/Style/Eva/Xuaii/FilterProgress.scss';
import {useSelector} from "../../../store/reducer";
import useCoordi from "../../Teacher/useCoordi";
import useCurrentYear from "./UseCurrentYear";
import {useEffect, useState} from "react";
import useGeneralData from "./useGeneralData";
import {getUser, UserInterface} from "../../../utils/user";
import {apiAuth} from "../../../utils/api";

interface TypeProgressFilter {
    setYear?: React.SetStateAction<any>
    setValue?: React.SetStateAction<any>
    groupSede?: React.SetStateAction<any>
    setUpdateGroup?:React.SetStateAction<any>
    setCampus?:React.SetStateAction<any>
    institutions?:React.SetStateAction<any>
}

const FilterProgress = ({setYear, setValue, /*groupSede,*/setUpdateGroup,/*institutions,*/setCampus}: TypeProgressFilter) => {
    let location = useLocation();
    const {
        user,
        data,
    } = useCoordi();
    const {
        groupSede,
        setGegree,
        institutions,
        setInstitution,
    } = useGeneralData();

    const {
        currentYear
    } = useCurrentYear();
   // const dataRoles = useSelector((state) => state.DataRoles)


    const targetHandler = (e: number) => {
        setYear(e);
    }
    const targetHandlerCheckBox = (e: string) => {
        setSelectedOption(e);
        setValue(e);
    }
    const targetHandlerInstitution=(e:any)=>{
        if (user?.roles === 'Directive' &&  location.pathname !== '/teacher/nomands' ) setCampus(e);
        setInstitution(e)
    }
    const targetHandlerCampus=(e:any)=>{
        setGegree(e)
        setUpdateGroup(e);
    }
    useEffect(() => {
        console.log(institutions);
    }, []);

    const [institutionData, setInstitutionData] = useState<number>(0);
    React.useEffect(() => {
        const user: UserInterface = getUser();
        apiAuth.get("user/data/" + user?.id, {
            headers: {
                "access-token": `${localStorage.getItem("token")}`
            },
        })
            .then((response) => {
                setInstitutionData(response.data.institution.id);
            }).catch((err) => {
            console.log(err);
        });
    }, []);
    const [selectedOption, setSelectedOption] = useState("entry");
    return (
        <div className="filter-container">
            <div className="filter-section-one">
                {user?.roles != 'Mentor' &&
                    < button className={`filter-section-one__button ${location.pathname === '/data/general' && "filter-section-one__button--hover"}`}>
                        <Link className="filter-section-one__link" to="/data/general">Informe general</Link>
                    </button>
                }
                <button className={`filter-section-one__button ${location.pathname === '/teacher/tribes' && "filter-section-one__button--hover"}`}>
                    <Link className="filter-section-one__link" to="/teacher/tribes">Informe grupal</Link>
                </button>
                <button className={`filter-section-one__button ${location.pathname === '/teacher/nomands' && "filter-section-one__button--hover"}`}>
                    <Link className="filter-section-one__link" to="/teacher/nomands">Informe individual</Link>
                </button>
            </div>
            <div className="filter-section-two">
                <section className={`filter-section-two__sub-section-two `}>
                    <select
                        className={`filter-section-two__drop-down ${user?.roles !== 'Directive' && 'filter-section-two__drop-down__disabled'}`}
                        name="" id="filterCampus"
                        onChange={(e: any) => targetHandlerInstitution(JSON.parse(e.target.value))}
                    >
                        {institutions.length >1  ?
                            <>
                                <option key={institutions[0]?.name} value={JSON.stringify({
                                    name: `${institutions[0]?.name}`,
                                    id: institutions[0].id
                                })}>{institutions[0]?.name}</option>
                                {institutions.map((item: any) => {
                                    if (item?.name != institutions[0]?.name)
                                        return (
                                            <option key={item?.id} value={JSON.stringify({
                                                name: item?.name,
                                                id: item.id
                                            })}>{item?.name}</option>
                                        )
                                })}
                            </>
                            :
                            institutionData === 0 || institutionData === 21 ? (
                                <option  value="/" key={data.institution?.name}>{`${data.institution?.name}`}</option>
                                ): (
                                <option  value="/" key={data.institution?.name}>{`Centro de experiencia: ${data.institution?.name}`}</option>
                            )
                        }


                    </select>
                </section>
                {
                    location.pathname !== '/teacher/nomands' &&
                    <section className="filter-section-two__sub-section-two">
                        <section className="filter-section-two__year">
                            <div className="filter-section-two__year__name">
                                Selecciona un año:
                            </div>
                            <select className="filter-section-two__year__selection" name="" id=""
                                    onClick={(e: any) => targetHandler(parseInt(e.target.value))}
                            >
                                <option
                                    value={currentYear[currentYear.length - 1]}>{currentYear[currentYear.length - 1]}</option>
                                {
                                    currentYear.map((item,index) => {
                                        if (item != currentYear[currentYear.length - 1])
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                    })
                                }
                            </select>
                        </section>
                        <section className="filter-section-two__type">
                            <div className="filter-section-two__type__name">
                                Tipo de prueba:
                            </div>
                            <div className="custom-control custom-radio">
                                <input
                                    type="radio"
                                    id="customRadio1"
                                    name="customRadio"
                                    className="custom-control-input"
                                    value="entry"
                                    onChange={(e: any) => targetHandlerCheckBox(e.target.value)}
                                    checked={selectedOption === "entry"}
                                />
                                <label className="filter-section-two__type__label custom-control-label label-color-text"
                                       htmlFor="customRadio1">Entrada</label>
                            </div>
                            <div className="custom-control custom-radio">
                                <input
                                    type="radio"
                                    id="customRadio2"
                                    name="customRadio"
                                    className="custom-control-input"
                                    value="output"
                                    onChange={(e: any) => targetHandlerCheckBox(e.target.value)}
                                    checked={selectedOption === "output"}

                                />
                                <label className="filter-section-two__type__label custom-control-label label-color-text"
                                       htmlFor="customRadio2">Salida
                                </label>
                            </div>
                        </section>
                    </section>
                }

            </div>
            {
                location.pathname !== '/data/general' ||  user?.roles === 'Mentor' ?
                <div className="filter-section-three">
                    <section>
                        <select
                            className={`filter-section-three__drop-down ${(user?.roles === 'Directive' && location.pathname != '/data/general') || (user?.roles === 'Coordinador' && location.pathname !== '/data/general') ? '' : ''}`}
                            name="" id="filterGrade"
                            onChange={(e: any) => targetHandlerCampus(JSON.parse(e.target.value))}
                        >
                            {groupSede ?
                                <>


                                    {/*
                                        <option value={JSON.stringify({
                                            id: groupSede[2]?.id,
                                            name: groupSede[2]?.name
                                        })}>{groupSede[2]?.name}</option>
                                        */
                                    }
                                    {
                                        groupSede.map((item: any) => {
                                            if (item?.name != groupSede[2]?.name){
                                                return (<option
                                                        key={item?.id}
                                                        value={JSON.stringify({
                                                            id: item?.id,
                                                            name: item?.name
                                                        })}>{item?.name}</option>
                                                )
                                            }
                                        })
                                    }
                                </>
                                :
                                <option value="DEFAULT">Elije un grupo</option>
                            }
                        </select>
                    </section>
                </div>
                    :<></>
            }

        </div>
    );
};

export default FilterProgress;