// @flow
import {apiEva, apiEvaLocal} from "../../../utils/api";
import {useEffect, useState} from "react";
import {TypeAllCampus, worldviewXuaii} from "../types";
import {Card, Col} from "react-bootstrap";
import Chart from "react-apexcharts";
import bar from "../Chart/chartXuaiiAreGroup";
import * as React from "react";
import barWorldView from "../Chart/worldViewXuaii";

let objetComplet = {
    'Ciencias Naturales' : [],
    'Competencias Ciudadanas' : [],
    'Lenguaje':[],
    'Matematicas':[]
};
interface TypeFilter {
    year: React.SetStateAction<any>
    value: React.SetStateAction<any>
}
const DirectiveAllCampus = ({year,value}:TypeFilter) => {

    const [loading,setLoading]= useState<boolean>(true);
    const [loadingText,setLoadingText] = useState('');
    const [data,setData] = useState<[TypeAllCampus]>([
        {
            topic: "",
            correctPerformance: 0,
            incorrectPerformance: 0,
            count: 0
        },
    ]);
    const [area,setArea] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [series,setSeries] = useState<ApexAxisChartSeries>();

    //worldView
    const [worldView,setWorldView] = useState<worldviewXuaii>();

    const [Listening,setListening] = useState<ApexAxisChartSeries>();
    const [general,setGeneral] = useState<ApexAxisChartSeries>();
    const [reading,setReading] = useState<ApexAxisChartSeries>();
    const [worldviewCategories,setWorldviewCategories] = useState({ options: {
            xaxis: {
                categories: ['PRE A1','A1','A2','B1','B2'],
                title: {
                    text: 'Nivel',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                },
            },
        },});


    useEffect(()=>{
        setLoading(true);
        setLoadingText('Espera, cargando datos.');
        const params = {
            type:value,
            year:year
        }
        apiEva.patch<[TypeAllCampus]>('/responses-xuaii/all-centers/topic/performance',params)
            .then((response)=>{
                const data = response.data;
                setData(data)
                setLoading(false);
            })
            .catch(()=>{
                setLoading(true);
                setLoadingText('Sin datos disponibles.');
            })
        setLoading(true);
        setLoadingText('Sin datos disponibles.');

        apiEva.patch<worldviewXuaii>('/responses-xuaii/all-centers/worldview/performance',params)
            .then((response)=>{
                const data = response.data;
                setWorldView(data)
            })


    },[value,year])


    useEffect(()=>{

        const organizedData = data.reduce((acc:any, item) => {
            const [subject, grade] = item.topic.split('_');
            if (!acc[grade]) {
                acc[grade] = {};
            }
            acc[grade][subject] = [Math.round(item.correctPerformance) ]
            return acc;
        }, {})

        for (let i = 3; i <= 11; i++) {
            const grade = i.toString();
            if (!organizedData[grade]) {
                organizedData[grade] = {
                    "Ciencias Naturales": [0],
                    "Competencias Ciudadanas": [0],
                    "Lenguaje": [0],
                    "Matematicas": [0]
                };
            }
        }
        for (const itemKey in organizedData) {
            if (Object.keys(organizedData[itemKey]).length < 4){

                const  KeysIn = Object.keys(organizedData[itemKey]);
                const keysCompleto = Object.keys(objetComplet);

                const only = keysCompleto.filter(clave => !KeysIn.includes(clave));
                only.forEach((item) =>{
                    organizedData[itemKey][item] = [0]

                })
            }
        }
        const Lenguaje = [];
        const Matematicas =[];
        const CienciasNaturales = [];
        const CompetenciasCiudadanas = [];

        for (const key in organizedData) {
            let value = organizedData[key];
            for (const keyKey in value) {

                switch (keyKey) {
                    case 'Lenguaje':
                        Lenguaje.push(value[keyKey][0])
                        break;
                    case 'Matematicas':
                        Matematicas.push(value[keyKey][0])
                        break;
                    case 'Ciencias Naturales':
                        CienciasNaturales.push(value[keyKey][0])
                        break;
                    case 'Competencias Ciudadanas':
                        CompetenciasCiudadanas.push(value[keyKey][0])
                        break;
                }
            }
        }

        const categorie:any = [['3'],['4'],['5'],['6'],['7'],['8'],['9'],['10'],['11']];

        const dataSeries:ApexAxisChartSeries =[
            {name:"Lenguaje",data: Lenguaje},
            {name:"Matematicas",data:Matematicas},
            {name:"CienciasNaturales",data: CienciasNaturales},
            {name:"CompetenciasCiudadanas",data: CompetenciasCiudadanas},
        ]

        setArea(()=>({
            options: {
                xaxis: {
                    categories: categorie,
                    title:{
                        text: 'Área',
                        style:{
                            fontWeight: 200,
                            fontSize: '15px',

                        }
                    }
                }
            }
        }))

        setSeries(dataSeries)
    },[data])

    useEffect(()=>{
        const stateVariables = {
            "listening_and_Vocabulary": setListening,
            "general":setGeneral,
            "reading":setReading,
        };

        for (const key in worldView) {
            let value = worldView[key]
            let series = [];
            let state = stateVariables[key as keyof typeof stateVariables];
            for (const valueKey in value) {
                series.push(value[valueKey])
            }
            state([{name:key,data:series}])
        }
    },[worldView])

    return (
        <div className="" id="">

            {
                series?
                <Col >
                    {

                        <>
                            <br/>
                            <Card  className="shadow-none" style={{maxWidth: 900, margin:"auto"}}>
                                <Card.Header className="text-center"><h5>Porcentaje alcanzado Sede</h5></Card.Header>
                                <Card.Body>
                                    {loading && loadingText}
                                    {!loading &&
                                    <Chart
                                        {...bar}
                                        series={series}
                                        options={{
                                            ...bar.options,
                                            ...area.options,
                                        }}
                                    />
                                    }
                                </Card.Body>
                            </Card>
                            <br/>
                        </>

                    }
                </Col>
                    :
                    <></>
            }

            <>

                {
                    Listening?
                        <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                            <Card.Header className="text-center"><h5>Listening and Vocabulary</h5></Card.Header>
                            <Card.Body>
                                <Chart
                                    {...barWorldView}
                                    series={Listening}
                                    options={{
                                        ...barWorldView.options,
                                        ...worldviewCategories.options,
                                    }}

                                />
                            </Card.Body>
                        </Card>
                        :<></>

                }
                <br/>
                {
                    reading?

                        <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                            <Card.Header className="text-center"><h5>Reading</h5></Card.Header>
                            <Card.Body>
                                <Chart
                                    {...barWorldView}
                                    series={reading}
                                    options={{
                                        ...barWorldView.options,
                                        ...worldviewCategories.options,
                                    }}

                                />
                            </Card.Body>
                        </Card>:<></>
                }
                <br/>
                {
                    general?
                        <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                            <Card.Header className="text-center"><h5>General</h5></Card.Header>
                            <Card.Body>
                                <Chart
                                    {...barWorldView}
                                    series={general}
                                    options={{
                                        ...barWorldView.options,
                                        ...worldviewCategories.options,
                                    }}

                                />
                            </Card.Body>
                        </Card>:<></>
                }
            </>:<></>
        </div >
    );
};

export default DirectiveAllCampus;