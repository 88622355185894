import html2pdf from 'html2pdf.js';
import React, {useEffect, useRef, useState} from 'react';
import Download from "../Loaders/Download";
import buttonPDF from 'src/assets/Xuaii/pdf off_1.png';
import buttonPDFHover from 'src/assets/Xuaii/pdf hover.png';
import loading from "./Loading";
//import bgTemplate from "../../assets/Xuaii/BGNuevo.png";
import bgTemplate from "../../assets/Xuaii/plantilla.jpg";
import {getToken, getUser, UserInterface} from "../../utils/user";
import {setRoles} from "../../redux/actions";
import {useSelector} from "../../store/reducer";
import {api} from "../../utils/api";

interface PDFProps {
    nameStudent?: string;
    campusName?: string;
    studentId?: number;
}
const DownloadPDF2 = ({nameStudent, campusName, studentId}: PDFProps) => {
    const [loading,setLoading] = useState(false);
    const [hoveredButton, setHovered] = useState(false);
    const [user, setUser] = React.useState<UserInterface>();
    const Redux = useSelector((state) => state.DataRoles)

    const elementsById = ['performance-by-student-', 'competence-data', 'english-data'];
    const filterButtonsRef = useRef(null);
    const performanceDataRef = useRef(null);
    const questionsDataRef = useRef(null);
    const competencesDataRef = useRef(null);
    const englishDataRef = useRef(null);
    const [isEnd, setEnd] = useState(false);
    const [grade, setGrade] = useState('');
    const [campus, setCampus] = useState('');
    const [year, setYear] = useState('');
    const [testType, setTestType] = useState('');
    const [product, setProduct] = useState('');



    const grades = [
        {
            grade: 'Sexto',
            separator: '45%',
            space: '300px',
        },
        {
            grade: 'Séptimo',
            space: '600px',
            separator: '43%',
        },
        {
            grade: 'Octavo',
            space: '400px',
            separator: '45%',
        },
        {
            grade: 'Décimo',
            space: '800px',
            separator: '41%',
        },
        {
            grade: 'Undécimo',
            space: '900px',
            separator: '30%',
        }
    ]

    const headers = {
        "access-token": getToken(),
    };

    React.useEffect(() => {
        const user: UserInterface = getUser();
        setUser(user);
        const cookies = document.cookie.split(';');
        for (let i = cookies.length - 1; i >= 0; i--) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('t=')) {
                setProduct(cookie.substring('t='.length));
            }
        }

    }, []);

    const convertToPDF = async () => {
        const filterDiv = document.getElementById('filterButtons-'+studentId);
        if (filterDiv) {
            const filterButtons = filterDiv.querySelectorAll('button');
            const performanceData = document.createElement('div');
            const questionsData = document.createElement('div');
            const competencesData = document.createElement('div');
            const englishData = document.createElement('div');
            // @ts-ignore
            performanceDataRef.current = performanceData;
            // @ts-ignore
            questionsDataRef.current = questionsData;
            // @ts-ignore
            competencesDataRef.current = competencesData;
            // @ts-ignore
            englishDataRef.current = englishData;

            const campus = document.getElementById('filterCampus') as HTMLSelectElement;
            const gradeHtml = document.getElementById('filterGrade') as HTMLSelectElement;
            const year = document.getElementById('select-year-'+studentId) as HTMLSelectElement;
            const testType = document.getElementById('radio-type-'+studentId) as HTMLSelectElement;

            if(campus !== null){
                let campusSelected = "";
                if(user?.roles !== "Directive"){
                    const campusValue = campus.options[0].text;
                    const campusObject = campusValue.split(':');
                    campusSelected = campusObject[1];
                }else{
                    campusSelected = campus.options[campus.selectedIndex].value;
                    campusSelected = JSON.parse(campusSelected).name;
                }
                setCampus(campusSelected);
            }
            let yearSelected = ''
            if(year !== null){
                yearSelected = year.options[year.selectedIndex].value;
                setYear(yearSelected);
            }
            let gradoEstudiante = '';
            let lastYear = false;
            let gradoEncontrado = null;
            if(grade !== null){
                let gradeSelected= "";
                const currentDate = new Date();
                if(currentDate.getFullYear() !== parseInt(yearSelected)){
                    lastYear = true;
                    await api.get("group_students_user/grade/" + studentId, {headers}).then((response) => {
                        const data = response.data;
                        gradeSelected = (data.grado-(currentDate.getFullYear() - parseInt(yearSelected))).toString();
                    });
                }else{
                    if(user?.roles !== "Mentor"){
                        gradeSelected = gradeHtml.options[gradeHtml.selectedIndex].value;
                        gradeSelected = JSON.parse(gradeSelected).name;
                    }else{
                        await api.get("group_students_user/grade/" + studentId, {headers}).then((response) => {
                            const data = response.data;
                            gradeSelected = data.grupo;
                        });
                    }
                }
                setGrade(gradeSelected);
                if(gradeSelected){
                    if(!lastYear){
                        // @ts-ignore
                        gradoEstudiante = gradeSelected.match(/\b([A-Za-záéíóúñü]+)\b/)[0];
                        gradoEncontrado = grades.find(item => item.grade === gradoEstudiante);
                    }
                }
            }

            if (testType) {
                const radioSeleccionado = testType.querySelector('input[type="radio"]:checked') as HTMLSelectElement;
                if (radioSeleccionado) {
                    const valorSeleccionado = radioSeleccionado.value;
                    setTestType(valorSeleccionado);
                }
            }

            for (let index = 0; index < filterButtons.length; index++) {
                const filterButton = filterButtons[index];
                if (filterButton instanceof HTMLButtonElement) {
                    filterButton.click();
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    if (index === 0) {
                        const buttonsByArea = document.querySelectorAll('.board-by-student-'+studentId);
                        for (const button of buttonsByArea) {
                            if (button instanceof HTMLButtonElement) {
                                button.click();
                            }
                        }
                        const questionsByStudent = document.querySelectorAll('.questions-by-student-'+studentId)
                        for(const question of questionsByStudent){
                            let questionData = null;
                            if (question !== null) {
                                questionData = question.cloneNode(true) as HTMLElement;
                                if(questionData){
                                    const divSeparator = document.createElement('div');
                                    questionData.style.minWidth = '701px';
                                    questionData.style.maxWidth = '701px';

                                    if(gradoEncontrado){
                                        divSeparator.style.marginBottom = gradoEncontrado.separator;
                                    }else{
                                        divSeparator.style.marginBottom = '40%';
                                    }
                                    questionData.style.height = 'auto';
                                    questionsData.appendChild(questionData);
                                    questionsData.appendChild(divSeparator);
                                }
                            }
                        }
                        const element = document.getElementById(elementsById[index]+studentId);
                        if (element !== null) {
                            const performance = element.cloneNode(true);
                            performanceData.appendChild(performance);
                        }

                    } else if(index === 1) {
                        const element = document.getElementById(elementsById[index]);
                        if (element !== null && element instanceof HTMLElement) {
                            const competence = element.cloneNode(true) as HTMLElement;
                            competence.style.maxWidth = '701px';
                            competence.style.minWidth = '701px';
                            competencesData.appendChild(competence);
                        }
                    } else if(index === 2){
                        const element = document.getElementById(elementsById[index]);
                        if (element !== null) {
                            const english = element.cloneNode(true) as HTMLElement;
                            english.style.minHeight = '320px';
                            englishData.appendChild(english);
                        }
                        setEnd(true);
                        break;
                    }
                }
            }
        }
    };

    useEffect(() => {
        const opts = {
            margin: 0,
            filename:   `${nameStudent}.pdf`,
            image: { type: 'jpeg', quality: 0.5 },
            html2canvas: { scale: 3 },
            jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' },
        };

        const performanceData = performanceDataRef.current;
        const questionsData = questionsDataRef.current;
        const competencesData = competencesDataRef.current;
        const englishData = englishDataRef.current;
        if (!performanceData || !questionsData || !competencesData || !englishData) return;

        const currentDate = new Date();
        const performance: HTMLElement = performanceData as HTMLElement;
        const questions: HTMLElement = questionsData as HTMLElement;
        const competences: HTMLElement = competencesData as HTMLElement;
        const english: HTMLElement = englishData as HTMLElement;

        const questionsQuantity = questions.querySelectorAll('div').length / 4;
        const htmlContent = `
        <!DOCTYPE html>
        <html lang="es">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1">      
                <style>
                    @page {
                        size: a4 portrait;
                    }
                </style>
            </head>
            <body>
                <div class="fullBody" style="
                    background-image: url(${bgTemplate}) !important; 
                    background-size: contain !important; 
                    background-position: center !important;
                ">
                    <div class="content">
                        <div class="title-report">
                            <div class="column-report">
                                <div class="row-1">
                                    <p style="margin: 10px; font-weight: bold !important; font-size: 16px;">Resultados de Prueba ${testType === 'entry' || testType === 'output' || product === 'El Engaño del Sultán' ? 'de':''} ${testType === 'entry' ? 'Entrada' : testType === 'output' ? 'Salida' : product === 'El Engaño del Sultán' ? 'Seguimiento' : 'Interna' } en ${product} ${year}</p>
                                </div>
                                <div class="row-2 info">
                                    <div class="student header-data" style="width: 60%">
                                        <p style="margin-bottom: 5px; font-weight: bold !important; font-size: 12px;">Nombre del Estudiante: <span class="second-text">${nameStudent}</span></p>
                                    </div>
                                    <div class="campus header-data" style="width: 40%">
                                        <p style="margin-bottom: 5px; font-weight: bold !important; font-size: 12px;">Centro de Experiencia: <span class="second-text">${campus}</span></p>
                                    </div>
                                </div>
                                <div class="row-3 info">
                                    <div class="grade header-data" style="width: 35%">
                                        <p style="margin-bottom: 5px; font-weight: bold !important; font-size: 12px;">Grado: <span class="second-text">${grade}</span></p>
                                    </div>
                                    <div class="date header-data" style="width: 65%">
                                        <p style="margin-bottom: 5px; font-weight: bold !important; font-size: 12px;">Fecha de generación del Informe: <span class="second-text">${currentDate.toLocaleDateString()}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="summary">
                            <div class="performance">
                                <div class="subtitle">
                                    <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 12px;">Resultados generales por módulos</p>
                                </div>
                                <div class="dataQuery">
                                    ${performance.innerHTML}
                                </div>
                            </div>
                            <div class="competences ${questionsQuantity === 2 ? 'two-competences' : 'four-competences'}">
                                <div class="subtitle">
                                    <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 12px;">Resultados por competencias</p>
                                </div>
                                <div class="">
                                    <div>
                                        ${competences.innerHTML}
                                    </div>
                                    <div style="${questionsQuantity === 2 ? 'margin-top:-10%' : 'margin-top:10%'}">
                                        ${english.innerHTML}
                                    </div>
                                </div>
                            </div>
                            <div class="questions" style="${questionsQuantity === 2 ? 'margin-top:39%;' : 'margin-top:100%;'} margin-bottom: -15%">
                                <div class="subtitle">
                                    <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 12px;">Resultados por preguntas</p>
                                </div>
                                <div class="dataQuery">
                                    ${questions.innerHTML}
                                </div>
                            </div>
                            <div style=" color: white">
                            .
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </html>
        <style>
            .fullBody{
                margin: 0;
                padding: 0;
                background-image: url(${bgTemplate}) !important; 
                background-size: contain !important; 
                background-position: center !important;
            }
            .content {
                background-image: url(${bgTemplate}) !important; 
                                    background-size: contain !important; 

                margin: 0;
                padding: 60px;
                padding-bottom: 0;
            }
            .title-report{
                display: flex;
                flex-direction: column;
                justify-content: center !important;
                align-items: center !important;
                align-content: center !important;
                width: 100%;
                font-weight: bold;
                font-size: 20px;
            }
            .column-report{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-content: center !important;
                align-items: center !important;
                justify-content: center !important;
            }
            .second-text{
                color: black;
                font-weight: normal !important;
            }
            .info{
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-around !important;
                background-color: #e6edf9;
                color: #6980d5;
        
            }
            .row-1{
                background-color: #6980d5;
                border-radius: 15px 15px 0 0;
                color: white;
                width: 100%;
                display: flex;
                justify-content: center !important; 
                font-size: 12px;
                font-weight: bold !important;
            }
            .header-data{
                border: 1px solid #9bace3;
                display: flex;
                justify-content: center !important;
            }
            .summary{
                display: flex;
                flex-direction: column;
                align-items: center !important;
                flex-wrap: nowrap;
                width: 100%;
            }
            .subtitle {
                background-color: #6980d5;
                border-radius: 15px 15px 15px 15px;
                color: white;
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center !important;
                align-items: center !important;
                align-content: center;
                font-size: 10px;
                height: 30px;
            }
            .two-competences{
                margin-top: 35%;
            }
            .four-competences{
                margin-top: 35%;
            }
        
            .performance, .questions, .competences{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center !important;
                align-items: center !important;
                align-content: center !important;
            }
            .performance{
            margin-bottom: 25%;
            }
            .dataQuery{
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                justify-content: center !important;
                align-items: center !important;
                align-content: center !important;
            }
        </style>
    `;
        html2pdf()
            .from(htmlContent)
            .set(opts)
            .save();
    }, [isEnd]);

    useEffect(() => {
        const filterDiv = document.getElementById('filterButtons-78');
        if (filterDiv) {
            const filterButtons = filterDiv.querySelectorAll('button');
            // @ts-ignore
            filterButtonsRef.current = filterButtons;
        }
    }, []);

    return (
        <div style={{textAlign: "center", marginTop: "-10%"}}>
            <button
                className="download-excel"
                onClick={() => convertToPDF()}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <>
                    {loading && <Download/>}
                </>
                {!loading && (
                    <img
                        src={hoveredButton ? buttonPDFHover : buttonPDF}
                        alt=""
                        className="Button-download-excel"
                    />
                )}
            </button>
        </div>
    );
};
export default DownloadPDF2;