import * as React from 'react';
import { RouteObject } from './route'
import dashBoardCoordinador from "./views/Coordinador/DashBoardCoordinador";

const OtherSamplePage = React.lazy(() => import('./Demo/Other/SamplePage'));
const DashboardTeachers = React.lazy(() => import('./views/Teacher/Dashboard'));
const Students = React.lazy(() => import('./views/Students/Students'));
const StudentProfile = React.lazy(() => import('./views/Students/ProfileStudent'));
const AdminTeachers = React.lazy(() => import('./views/AdminTeachers/Teacher'));
const AdminDashboard = React.lazy(() => import('./views/Admins/Dashboard'));
const Graphics = React.lazy(() => import('./views/Eva/Graphics'));
const CompareGraphics = React.lazy(() => import('./views/Eva/CompareGraphics'));
const CompareFullGraphics = React.lazy(() => import('./views/Eva/CompareFullGraphics'));

//Directive
const DashboardDirectives = React.lazy(()=> import('./views/Directives/Dashboard-Directives'));
const GroupDirectives = React.lazy(()=> import('./views/Directives/Group'));

//Coordi
const DashboardCoordi = React.lazy(()=>import('./views/Coordinador/DashBoardCoordinador'))

const Destines = React.lazy(() => import('./views/TravelGuide/Destines'));
const DestinesSearch = React.lazy(() => import('./views/TravelGuide/Destines_Search'));

const Destines_test_new = React.lazy(() => import('./views/TravelGuide/Destines_test_new'));
const Destines_t2 = React.lazy(() => import('./views/TravelGuide/Destines_t2'));
const Destines_t3 = React.lazy(() => import('./views/TravelGuide/Destines_t3'));
const Destines_t4 = React.lazy(() => import('./views/TravelGuide/Destines_t4'));
const Destines_t5 = React.lazy(() => import('./views/TravelGuide/Destines_t5'));
const Destines_t6 = React.lazy(() => import('./views/TravelGuide/Destines_t6'));
const Destines_t7 = React.lazy(() => import('./views/TravelGuide/Destines_t7'));
const Destines_t10 = React.lazy(() => import('./views/TravelGuide/Destines_t10'));
const Destines_PIHE = React.lazy(() => import('./views/Pihe/Destines_PIHE'));
const ExploreContents = React.lazy(() => import('./views/Teacher/ExploreContents'));

const Xuaii = React.lazy(() => import('./views/TravelGuide/Destines_Xuai'));
const XuaiiContainerGeneral = React.lazy(()=> import('./views/xuaii/XuaiiContainerGeneral'));


//Pihe
const Guia = React.lazy(() => import('./views/Teacher/Guias'));
const ContextoNarrativo= React.lazy(() => import('./views/Teacher/ContextoNarrativo'));
const Evaluacion= React.lazy(() => import('./views/Teacher/Evaluacion'));
const HomePIHE= React.lazy(() => import('./views/Pihe/Home-Pihe'));
const Orientacion= React.lazy(() => import('./views/Teacher/Orientacion'));


//seluverso
const EnfoqueEducativo= React.lazy(() => import('./views/Teacher/EnfoqueEducativo'));


const Tribes = React.lazy(() => import('./views/Eva/Tribes'));
const Rutes = React.lazy(() => import('./views/TravelGuide/Rutes'));
const Worlds = React.lazy(() => import('./views/TravelGuide/Worlds'));
const Levels = React.lazy(() => import('./views/TravelGuide/Levels'));
const Nomands = React.lazy(() => import('./views/Eva/Nomands'));
const CompareTribes = React.lazy(() => import('./views/Eva/CompareTribes'));
const MomentoSelecu = React.lazy(()=>import('./views/SelecuMoment/MomentSelecu'))
const Momento = React.lazy(()=>import('./views/Teacher/Momento'))


const routes: RouteObject[] = [
    { path: '/sample-page', exact: true, name: 'Sample Page', component: OtherSamplePage },
    // Teachers
    { path: '/teacher/dashboard', exact: true, name: 'Teacher Dashboard', component: DashboardTeachers },
    { path: '/teacher/students', exact: true, name: 'Students', component: Students },
    { path: '/students/view/:userId', exact: false, name: 'Student profile', component: StudentProfile },
    {path: '/teacher/momento-Selecu',exact: true, name: 'Momento selecu', component: Momento},
    //directives
    { path: '/directive/tablero', exact: true, name: 'Directive tablero', component: DashboardDirectives },
    { path: '/directive/tribes', exact: true, name: 'Directive Tribes', component: GroupDirectives },

    //coordi
    {path: '/coordinador/tablero', exact:true, name: 'tablero coordinador', component: dashBoardCoordinador},

    // Admins
    { path: '/admin/dashboard', exact: true, name: 'Admin Dashboard', component: AdminDashboard },
    { path: '/admin/teachers', exact: true, name: 'Admin Teachers', component: AdminTeachers },
    { path: '/teacher/graphics', exact: true, name: 'Graphics', component: Graphics },
    { path: '/teacher/compareGraphics', exact: true, name: 'CompareGraphics', component: CompareGraphics },
    { path: '/teacher/compareFullGraphics', exact: true, name: 'CompareFullGraphics', component: CompareFullGraphics },
    { path: '/teacher/Destines', exact: true, name: 'Destines', component: Destines },
    { path: '/teacher/Destines_test_new', exact: true, name: 'Destines_test_new', component: Destines_test_new },
    { path: '/teacher/Destines_t2', exact: true, name: 'Destines_t2', component: Destines_t2 },
    { path: '/teacher/Destines_t3', exact: true, name: 'Destines_t3', component: Destines_t3 },
    { path: '/teacher/Destines_t4', exact: true, name: 'Destines_t4', component: Destines_t4 },
    { path: '/teacher/Destines_t5', exact: true, name: 'Destines_t5', component: Destines_t5 },
    { path: '/teacher/Destines_t6', exact: true, name: 'Destines_t6', component: Destines_t6 },
    { path: '/teacher/Destines_t7', exact: true, name: 'Destines_t7', component: Destines_t7 },
    { path: '/teacher/Destines_t10', exact: true, name: 'Destines_t10', component: Destines_t10 },
    { path: '/teacher/Destines_PIHE', exact: true, name: 'Destines_PIHE', component: Destines_PIHE },
    { path: '/teacher/Destines_Buscando a Xuaii', exact: true, name: 'Destines_Buscando a Xuaii', component: Xuaii },

    { path: '/teacher/Tribes', exact: true, name: 'Tribes', component: Tribes },
    { path: '/teacher/Rutes', exact: true, name: 'Rutes', component: Rutes },
    { path: '/teacher/Worlds', exact: true, name: 'Worlds', component: Worlds },
    { path: '/teacher/Levels', exact: true, name: 'Levels', component: Levels },
    { path: '/teacher/Nomands', exact: true, name: 'Nomands', component: Nomands },
    { path: '/teacher/CompareTribes', exact: true, name: 'CompareTribes', component: CompareTribes },
    { path: '/teacher/Destines_Search', exact: true, name: 'Destines_Search', component: DestinesSearch },
    { path: '/teacher/ExploreContents', exact: true, name: 'ExploreContents', component: ExploreContents },
    {path: '/data/general', exact: true, name: 'XuaiiContainerGeneral', component: XuaiiContainerGeneral},


    { path: '/teacher/Enfoque_educativo', exact: true, name: 'Enfoque_educativo', component: EnfoqueEducativo },
    //PIHE
    { path: '/teacher/Instructivo', exact: true, name: 'Guia', component: Guia},
    { path: '/teacher/Orientacion', exact: true, name: 'Orientacion', component: Orientacion },

    { path: '/teacher/Contexto_Narrativo', exact: true, name: 'Contexto_Narrativo', component: ContextoNarrativo },

    { path: '/teacher/Evaluacion', exact: true, name: 'Evaluacion_Sultan', component: Evaluacion },

    /*
  { path: '/teacher/Contexto_Narrativo', exact: true, name: 'Guia_Pihe', component: ContextoNarrativo },
*/

];
export default routes;
